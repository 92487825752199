.header{
    @apply relative;
}
.header img{
    @apply w-full h-full hidden md:block;
}
.body{
    @apply h-screen;
}
.sticky-container{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: white;
    padding: 30px 50px;
    padding-bottom: 20px;
    font-size: 20px;
    z-index:10;
    /* @apply flex flex-row; */
    @apply flex flex-row justify-between;
}
.filter{
    @apply text-left flex-1 cursor-pointer;
}
.filter i,.menu i{
    @apply mr-3;
}
.menu{
    @apply text-right cursor-pointer;
}
.image-catalog{
    @apply relative;
}
.image-catalog img{
    @apply w-full h-full;
}
.image-catalog-info{
    @apply absolute inset-0;
}
.image-catalog-info li{
    @apply flex absolute;
}
.image-catalog-dot{
    @apply rounded-full bg-white w-3 h-3 opacity-75 shadow-md cursor-pointer;
    border-radius: 50%; 
    box-shadow: 0px 0px 0px 12px black;
}
.image-catalog-tag{
    @apply p-5 bg-white/70 flex flex-col hidden;
}
.image-catalog-tag.show{
    @apply flex;
}
.image-catalog-tag p{
    @apply py-3;
}
.image-catalog-tag button{
    @apply bg-red-700 text-white py-3 px-5 rounded-sm;
}
.image-catalog-tag.right{
    transform: translateY(-50%) translateX(1.5rem);
}
.image-catalog-tag.right-bottom {
    transform: translateY(calc(-100% + 1rem)) translateX(1.5rem)
}
.image-catalog-tag.right-top {
    transform: translateY(-1rem) translateX(1.5rem)
}
.image-catalog-tag.left{
    transform: translateY(-50%) translateX(-100%) translateX(-1.5rem);
}
.image-catalog-tag.left-top {
    transform: translateY(-1rem) translateX(-100%) translateX(-1.5rem)
}
.image-catalog-tag.left-bottom {
    transform: translateY(calc(-100% + 1rem)) translateX(-100%) translateX(-1.5rem)
}
.image-catalog-tag.bottom{
    transform: translateX(-50%) translateY(1.5rem);
}
.image-catalog-tag.bottom-left {
    transform: translateX(calc(-100% + 1rem)) translateY(1.5rem)
}
.image-catalog-tag.bottom-right {
    transform: translateX(-1rem) translateY(1.5rem)
}
.hr{
    @apply pb-5 border-b border-dotted border-white mb-5;
}
.copyright{
    @apply flex gap-5 items-center;
}
/* Home Page */
.homepage{
    @apply px-20;
}
/* Products Block */
.products-blocks{
    @apply w-full mb-20;
}
.products-blocks-1{
    @apply w-full mb-10 grid grid-cols-2 gap-4;
}
.products-blocks-1 .left,.products-blocks-1 .right{
    @apply col-span-1 w-full;
}
.products-blocks-1 .left img{
    @apply aspect-square ;
}
.products-blocks-1 .right{
    @apply flex flex-col gap-4 h-full;
}
.products-blocks-1 .right div{
    @apply h-1/2;
}
.products-blocks-1 .item-inner{
    @apply flex;
}
.products-blocks-1 .item-3{
    @apply w-full h-1/2;
}
.products-blocks-2{
    @apply w-full;
}
.products-blocks-2 ul{
    @apply w-full flex flex-col md:flex-row justify-start gap-5;
}
.products-blocks-2 li{
    @apply w-full lg:w-1/4;
}
.products-info{
    @apply w-full;
}
.products-blocks-2 .product-img{
    @apply aspect-square overflow-hidden;
}
.products-blocks-2 .product-img img{
    @apply hover:scale-110 transition duration-500 cursor-pointer object-cover hover:opacity-70;
}
.products-blocks-2 .products-detail{
    @apply overflow-hidden;
}
.products-blocks-2 .products-detail p{
    @apply px-4 py-3 text-center text-lg;
}
.products-blocks-2 .collection-info{
    @apply flex items-end h-full p-5;
}
.products-blocks-2 .collection-info .detail{
    @apply p-5;
}
.products-blocks-2 .collection-info .detail h4{
    @apply text-2xl pb-5;
}
.products-blocks-2 .collection-info .detail p{
    @apply text-base mt-[18px] mb-[10px] text-[#949494] ;
}
.products-blocks-2 .collection-info .detail i{
    @apply translate-x-2;
}
.products-blocks-2 .collection-info .detail a:hover i{
    @apply translate-x-5 delay-700 duration-300;
}
.line-separator{
    @apply border-b-[3px] border-black mt-[10px] mb-[10px] w-[65px];
}
/* Hilight Block */
.hilight-blocks{
    @apply w-full mb-20;
}
.hilight-blocks h3{
    @apply text-2xl pb-5;
}
.hilight-lists{
    @apply overflow-x-scroll w-full;
}
.hilight-lists ul{
    @apply flex flex-row gap-3 min-w-fit;
}
.hilight-lists li{
    @apply min-w-[360px] sm:w-[33vw] lg:w-[450px];
}
.hilight-lists img{
    @apply aspect-square min-w-[360px] sm:w-[33vw] lg:w-[450px];
}
.hilight-info{
    @apply relative h-[300px] p-8;
}
.hilight-info h4{
    @apply text-2xl pb-5;
}
.hilight-info p{
    @apply text-base;
}
.hilight-btn{
    @apply absolute bottom-5;
}
.next-btn{
    @apply text-black aspect-square w-[50px] rounded-full bg-white border-black/75 border-2 shadow-sm hover:opacity-80;
}
.white .next-btn{
    @apply text-white aspect-square w-[50px] rounded-full bg-black border-black/75 border-2 shadow-sm hover:opacity-80;
}
.brown{
    @apply bg-[#2d261d] text-white ;
}
.navi{
    @apply bg-[#202d4e] text-white ;
}
.black{
    @apply bg-[#000] text-white ;
}
.grey{
    @apply bg-[#f2ece3] text-black ;
}
/* Collection Block */
.collection-blocks{
    @apply grid grid-cols-1 lg:grid-cols-2 gap-5 mb-20;
}
.collection-blocks .image-catalog img{
    @apply rounded-xl shadow-xl;
}
.image-catalog-lists li{
    @apply flex items-start gap-5 py-5 first:pt-0 border-b;
    @apply relative;
}
.image-catalog-lists img{
    @apply w-[200px] aspect-square;
}
.image-catalog-lists p{
    @apply text-sm leading-5;
}
.image-catalog-lists h4{
    @apply text-lg pb-3;
}
.image-catalog-lists-btn{
    @apply absolute bottom-0 right-0 py-5 flex gap-3;
}
.catalog-btn{
    @apply aspect-square w-[50px] rounded-full bg-blue-600 opacity-80 hover:opacity-100 shadow-sm text-white;
}
.favorite-btn{
    @apply aspect-square w-[50px] rounded-full bg-white border-black/75 border-2 shadow-sm hover:bg-black/5;
}
/* product */
.product{
    @apply container mx-auto flex flex-row; 
}
.product .aside{
    @apply flex w-1/4 flex-col;
}
.product .aside h3{
    @apply text-lg pb-4;
}
.product .aside a{
    @apply font-light text-gray-400 hover:text-black;
}
.aside p{
    @apply text-xs leading-5;
}
.product .category-blocks{
    @apply w-full;
}
.aside-social a{
    @apply opacity-75 hover:opacity-100 hover:no-underline px-3;
}
.aside-social{
    @apply flex flex-row text-base mb-5;
}
/* product nav */
.nav-container{
    @apply bg-slate-100 w-full mb-10 py-12;
}
.nav-container .wrapper{
    @apply container w-full mx-auto flex justify-start items-center;
}
.nav-container a{
    @apply text-gray-400 hover:text-black ;
}
.nav-container ul{
    @apply flex;
}
.nav-container li{
    @apply after:content-['/'] after:text-gray-400 after:px-5 last:after:content-[''];
}
.nav-container li:last-child a{
    @apply text-black cursor-default;
}
/* product lists */
.product-lists{
    @apply grid grid-cols-2 md:grid-cols-3 gap-4;
}
.product-lists .img-wrapper{
    @apply relative h-fit flex flex-col cursor-pointer;
}
.product-lists img{
    @apply h-auto max-w-full rounded-lg;
}
.product-lists .img-wrapper span{
    /* @apply absolute rounded-lg top-0 right-0 left-0 bottom-0 bg-black bg-opacity-40 opacity-0 text-white hover:opacity-90 cursor-pointer flex justify-center items-center; */
    @apply py-2;
}
/* product detail */
.products-detail{
    @apply grid grid-cols-2 gap-4 mb-10 mx-auto;
}
.products-detail .product-info{
    @apply px-16 py-5;
}
.products-detail .product-info h1{
    @apply mb-5;
}
.products-detail .product-info p{
    @apply mb-2;
}
.products-detail .product-info button{
    @apply border-black border w-full py-5 text-center bg-white text-black my-5 capitalize hover:bg-slate-100;
}
.products-detail .product-img{
    @apply grid gap-4;
}
.products-detail .product-img img{
    @apply w-full h-auto max-w-full rounded-lg aspect-square;
}
.products-detail .product-img .img-lists{
    @apply grid grid-cols-5 gap-4 cursor-pointer;
}
.products-detail .product-desc{
    @apply col-span-2 w-full mb-16;
}
.products-detail .product-desc h3{
    @apply w-full bg-gray-200 p-5 py-5 my-5 text-xl;
}