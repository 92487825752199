/* ------------------------------ Base ------------------------------ */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./fontawesome/css/all.min.css";

@font-face {
    font-family: 'Kanit-Regular';
    src: url('~fonts/Kanit-Regular.ttf') format("truetype");
}
/* custom text selection */
::-moz-selection { /* Code for Firefox */
    background: #b482d1;
}
.collapse {
    visibility: visible;
}
::selection {
    background: #b482d1;
}
/* custom scrollbar */
::-webkit-scrollbar {
    width: 18px;
    @apply cursor-pointer;
}
::-webkit-scrollbar-track {
    background-color: transparent;
}
::-webkit-scrollbar-thumb {
    /* background-color: #d6dee1; */
    @apply bg-gray-300 bg-opacity-50 cursor-pointer;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}
::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}
html, body { 
    @apply h-full;
}
body { 
    --bg-color: #fff;
    --txt-color: #000;
    --scrollbar-width: 0px;
    --hundred-vw: calc(100vw - var(--scrollbar-width, 0px));
    --hundred-vh: 100vh;
    --layout-ratio: calc(937px / 1920px);
    --hundred-vh-wratio: calc(var(--hundred-vw) * 0.488);
    --layout-padding: 0px;
    --grid-gap: 16px;
    --inner-width: calc(var(--hundred-vw) - 32px);
    --column-count: 6;
    --column-unit: calc((var(--inner-width) + var(--grid-gap)) / var(--column-count));
    --body-padding: 16px;
    @apply font-body w-[var(--hundred-vw)];
}
#root, section{
    @apply w-[var(--hundred-vw)];
}
h1{
    @apply text-5xl;
}
h2{
    @apply text-xl;
}
p{
    @apply text-xl;
}